<template>
  <v-app id="login">
    <v-main>
      <v-container color="secondary" fluid fill-height>
        <Loader :loading="loading" />
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img class="animate__animated animate__bounceInLeft img-shadow" :src="logo" max-height="260" contain />
          </v-col>
          <v-col cols="1" class="itemsCenter d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card v-if="state == 1" class="animate__animated animate__bounceInRight mx-auto" elevation="5" max-width="500">
              <v-card-title color="primary" class="justify-center mb-3 main-title" v-text="'Inicio de sesión'" />

              <v-card-text>
                <v-form ref="formLogin" lazy-validation @keyup.enter.native="login()">
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="form.username" label="Email" prepend-inner-icon="mdi-email" :rules="rules.username" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="form.password" label="Contraseña" prepend-inner-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-btn block color="primary" class="white--text" :loading="loading" @click="login()">Entrar</v-btn>
                  </v-col>
                  <v-col cols="12" md="12">
                    <div class="pl-3 text-center">
                      <v-btn text small rounded elevation="0" :disabled="loading" @click="updateState(2)">¿Olvidaste tu contraseña?</v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <v-card v-if="state == 2" class="animate__animated animate__bounceInRight mx-auto" elevation="5" max-width="500">
              <v-card-title class="justify-center mb-3 main-title"> Recuperar contraseña </v-card-title>
              <v-card-text>
                <v-form ref="formForgot" lazy-validation @submit.prevent>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field v-model="forgot.email" label="Email" prepend-inner-icon="mdi-email" :rules="rules.email" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row align="center" justify="space-around" class="pa-3">
                  <v-btn large color="white" :disabled="loading" @click="updateState(1)">Volver</v-btn>
                  <v-btn large color="secondary" class="white--text" :loading="loading" @click="recovery()">Continuar</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
        <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-app>
</template>

<script>
import "animate.css";
import { Loader, BasicAlert } from "@/components";

export default {
  name: "login",
  components: {
    Loader,
    BasicAlert,
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    show:false,
    paths: {
      login: "/pub/admin/login",
      recoverPassword: "/pub/recover/password",
    },
    logo: require("@/assets/logo.png"),
    loading: false,
    state: 1,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    animationSpeed: 1,
    form: {
      email: "",
      password: "",
    },
    forgot: {
      email: "",
    },

    rules: {
      username: [(v) => !!v || "", (v) => (v && v.length <= 320) || "", (v) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || ""],
      password: [(v) => !!v || "", (v) => (v && v.length <= 60) || ""],
    },
  }),
  methods: {
    login() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true;
        let data = {
          username: this.form.username,
          password: this.form.password,
        };
        this.axios
          .post(this.host + this.paths.login, { data })
          .then((response) => {
            let res = response.data;
            this.$session.start();
            this.$session.set("jwt", res.token);
            this.$router.push("/home");
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (error.response) {
              this.alert.msg = error.response.data.message;
            } else {
              this.alert.msg = "Servicio no disponible. Intente más tarde";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    updateState(id) {
      if (this.state == 0) {
        // this.$refs.formLogin.reset();
      } else if (this.state == 1) {
        // this.$refs.formRegister.reset();
      } else if (this.state == 2) {
        // this.$refs.formForgot.reset();
      }

      this.state = id;
    },
    recovery() {
      if (this.$refs.formForgot.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forgot));
        this.axios
          .post(this.host + this.paths.recoverPassword, { data })
          .then((response) => {
            this.updateState(1);
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Solicitud enviada exitosamente";
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
  },
};
</script>

<style scoped>
.main-title {
  font-size: 28px;
  color: #222665;
}
</style>
